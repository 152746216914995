import { Component, Input } from '@angular/core';
import { BrandingModeService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'netfoundry-ui-ziti-assets',
    templateUrl: './ziti-assets.component.html',
    styleUrls: ['./ziti-assets.component.scss'],
})
export class ZitiAssetsComponent {
    @Input() isCloudZiti = false;

    constructor(public brandingService: BrandingModeService) {}
}
