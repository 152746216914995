import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureNavigatorModule } from '@netfoundry-ui/feature/navigator';
import { FeatureAppSwitcherModule } from '@netfoundry-ui/feature/app-switcher';
import { SideBarComponent } from './side-bar/side-bar.component';
import { ZitiAssetsComponent } from './ziti-assets/ziti-assets.component';

@NgModule({
    imports: [CommonModule, FeatureNavigatorModule, FeatureAppSwitcherModule],
    declarations: [SideBarComponent, ZitiAssetsComponent],
    exports: [SideBarComponent, ZitiAssetsComponent],
})
export class SharedSideBarModule {}
