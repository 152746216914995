<div class="router-hosting-config-container">
    <div class="title" *ngIf="isModal">{{ title }}</div>
    <div class="subtitle" [innerHtml]="subtitle"></div>
    <div class="close cancel" *ngIf="isModal" (click)="cancel()"></div>
    <div class="nf-hosted-config-row nf-hosted-config-grid-row">
        <div class="config-item">
            <div class="config-item-label-container">
                <span class="config-title">CLOUD PROVIDER</span>
                <span class="config-label" *ngIf="!readonly">REQUIRED</span>
            </div>
            <select
                id="Provider"
                [(ngModel)]="svc.nfRouterData.provider"
                (change)="getRegions($event)"
                *ngIf="canNFHost"
            >
                <option *ngFor="let provider of providers | sortby: 'type':'asc'" [value]="provider.type">
                    {{ provider.type }}
                </option>
            </select>
            <div class="config-container" *ngIf="!canNFHost">
                <div class="config-container-label">
                    {{ svc.nfRouterData.provider }}
                </div>
            </div>
        </div>

        <div class="config-item">
            <div class="config-item-label-container">
                <span class="config-title">LOCATION</span>
                <span class="config-label" *ngIf="!readonly">REQUIRED</span>
            </div>
            <span *ngIf="svc.errors['region']" class="error error-message">{{ svc.errors['region'] }}</span>
            <select
                (change)="regionChanged()"
                [(ngModel)]="svc.nfRouterData.locationCode"
                [ngClass]="{ error: svc.errors['region'] }"
                id="Region"
                *ngIf="canNFHost"
            >
                <option [value]="undefined">Select a Datacenter</option>
                <option *ngFor="let region of displayedRegions | sortby: 'city':'asc'" [value]="region.locationCode">
                    {{ region.city }} {{ region.locationCode }}
                </option>
            </select>
            <div class="config-container" *ngIf="!canNFHost">
                <div class="config-container-label">
                    {{ svc.nfRouterData.region }}
                </div>
            </div>
        </div>
    </div>
    <div class="nf-hosted-config-row nf-hosted-config-grid-row">
        <div class="config-item">
            <div class="config-item-label-container">
                <span class="config-title">LINK LISTENER</span>
                <span class="config-label" *ngIf="!readonly">OPTIONAL</span>
            </div>
            <div class="config-container toggle-container" *ngIf="!readonly">
                <div class="config-container-label">ENABLE LINK LISTENER</div>
                <div (click)="toggleLinkListener()" [ngClass]="{ on: svc.nfRouterData.linkListener }" class="toggle">
                    <span [hidden]="!svc.nfRouterData.linkListener" class="on-label">YES</span>
                    <span [hidden]="svc.nfRouterData.linkListener" class="off-label">NO</span>
                    <div class="switch"></div>
                </div>
            </div>
            <div class="config-container" *ngIf="readonly">
                <div class="config-container-label">
                    {{ svc.nfRouterData.linkListener ? 'ENABLED' : 'DISABLED' }}
                </div>
            </div>
        </div>
        <div class="config-item">
            <div class="config-item-label-container">
                <span class="config-title">WSS LISTENER</span>
                <span class="config-label" *ngIf="!readonly">OPTIONAL</span>
            </div>
            <div class="config-container toggle-container" *ngIf="canNFHost">
                <div class="config-container-label">ENABLE WSS</div>
                <div
                    (click)="toggleWSSListener()"
                    [ngClass]="{
                        on: svc.nfRouterData.wssListener,
                        disabled: readonly,
                        error: svc.errors['wssListener']
                    }"
                    class="toggle"
                >
                    <span [hidden]="!svc.nfRouterData.wssListener" class="on-label">YES</span>
                    <span [hidden]="svc.nfRouterData.wssListener" class="off-label">NO</span>
                    <div class="switch"></div>
                </div>
            </div>
            <div class="config-container" *ngIf="!canNFHost">
                <div class="config-container-label">
                    {{ svc.nfRouterData.wssListener ? 'ENABLED' : 'DISABLED' }}
                </div>
            </div>
        </div>
    </div>
    <div class="nf-hosted-config-column">
        <div class="config-item" *ngIf="showAltDomainField">
            <div class="config-item-label-container">
                <span class="config-title">{{
                    svc.nfRouterData.id ? 'ADVERTISED NAME' : 'CUSTOM DNS - SET ADVERTISED NAME'
                }}</span>
                <span class="config-label" *ngIf="!readonly">OPTIONAL</span>
            </div>
            <span *ngIf="svc.errors['alternateDomainName']" class="error error-message">{{
                svc.errors['alternateDomainName']
            }}</span>
            <input
                class="form-field-input"
                placeholder="uuid.mycompany.com"
                *ngIf="showAltDomainInput"
                [(ngModel)]="svc.nfRouterData.alternateDomainName"
                [ngClass]="{ error: svc.errors['alternateDomainName'] }"
                (keyup)="altDomainChangedDebounced()"
            />
            <div class="config-container" *ngIf="showAltDomainLabel">
                <div class="config-container-label domain-label">
                    {{ svc.nfRouterData.alternateDomainName }}
                </div>
            </div>
        </div>
        <div class="config-item disabled" *ngIf="readonly && svc.nfRouterData.domainName">
            <div class="config-item-label-container">
                <span class="config-title">NETFOUNDRY DNS</span>
            </div>
            <div class="config-container">
                <div class="config-container-label domain-label">
                    {{ svc.nfRouterData.domainName }}
                </div>
            </div>
        </div>
    </div>
    <div class="nf-hosted-config-row config-row-end">
        <div *ngIf="!readonly" (click)="cancel()" class="linkButton cancel">
            {{ cancelAction }}
        </div>
        <button
            class="save confirm"
            id="SaveHostRouter"
            (click)="updateHosting()"
            [ngClass]="{ disabled: svc.hosting }"
            *ngIf="!readonly"
        >
            {{ svc.hosting ? 'Updating' : readonly ? 'Edit Hosting' : 'Save' }}
            <span class="spinner" *ngIf="svc.hosting"></span>
        </button>
    </div>
</div>
