import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { EDGE_ROUTER_EXTENSION_SERVICE } from 'ziti-console-lib';
import { debounce, isEmpty, unset, delay } from 'lodash';
import { ProviderService, RegionService } from '@netfoundry-ui/shared/apiv2';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EdgeRoutersExtensionService } from '../edge-routers-extension/edge-routers-extension.service';

@Component({
    selector: 'netfoundry-ui-router-hosting-config',
    templateUrl: './router-hosting-config.component.html',
    styleUrls: ['./router-hosting-config.component.scss'],
})
export class RouterHostingConfigComponent implements OnInit {
    altDomainChangedDebounced = debounce(this.altDomainChanged.bind(this), 500);
    @Input() readonly = false;
    providers: any = [];
    displayedRegions: any = [];
    isModal = false;
    cancelAction = 'Never mind, cancel host editing';
    title = 'Hosting Configuration';
    subtitle: any;
    linkListenerChanged = false;
    initLinkListener = false;

    constructor(
        @Inject(EDGE_ROUTER_EXTENSION_SERVICE) public svc: EdgeRoutersExtensionService,
        private providerService: ProviderService,
        private regionService: RegionService,
        public dialogForm: MatDialog,
        @Optional() private dialogRef: MatDialogRef<RouterHostingConfigComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (!isEmpty(data)) {
            this.isModal = true;
            this.subtitle = data.subtitle;
            this.title = data.title || 'Hosting Configuration';
        }
        this.initLinkListener = this.svc.nfRouterData.linkListener;
    }

    ngOnInit() {
        this.getProviders();
        this.getRegions();
    }

    get canNFHost() {
        return (isEmpty(this.svc?.nfRouterData?.id) || this.svc.newRouter) && !this.readonly;
    }

    get showAltDomainField() {
        return this.svc.nfRouterData?.alternateDomainName || (!this.readonly && !this.svc.nfRouterData.id);
    }

    get showAltDomainInput() {
        return !this.readonly && !this.svc.nfRouterData?.id;
    }

    get showAltDomainLabel() {
        return (
            (this.readonly && this.svc.nfRouterData?.alternateDomainName) ||
            (!this.readonly && this.svc.nfRouterData?.alternateDomainName && this.svc.nfRouterData.id)
        );
    }

    toggleLinkListener() {
        this.svc.nfRouterData.linkListener = !this.svc.nfRouterData.linkListener;
        this.linkListenerChanged = this.svc.nfRouterData.linkListener !== this.initLinkListener;
    }

    toggleWSSListener() {
        this.svc.nfRouterData.wssListener = !this.svc.nfRouterData.wssListener;
    }

    regionChanged() {
        unset(this.svc.errors, 'region');
    }

    getProviders() {
        this.providerService.getProviderPage().then((providers) => {
            this.providers = providers;
        });
    }

    getRegions(event: any = {}) {
        this.regionService.findRegionsByProvider([this.svc.nfRouterData.provider]).subscribe((regions) => {
            this.displayedRegions = regions;
        });
    }

    altDomainChanged() {
        unset(this.svc.errors, 'alternateDomainName');
    }

    updateHosting() {
        if (this.linkListenerChanged && this.svc.nfRouterData.id) {
            this.svc.confirmLinkListenerChanged().then((result) => {
                if (result) {
                    delay(() => {
                        this.dialogRef?.close(true);
                    }, 500);
                }
            });
        } else {
            this.svc.saveNFRouter().then((result) => {
                if (result) {
                    this.dialogRef?.close(true);
                }
            });
        }
    }

    cancel() {
        this.svc.errors = {};
        this.dialogRef.close();
    }
}
