import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureGettingStartedModule } from '@netfoundry-ui/feature/getting-started';
import { SharedGrowlerModule } from '@netfoundry-ui/shared/growler';
import { FeaturePageTourModule } from '@netfoundry-ui/feature/page-tour';
import { SharedRecentAlertsModule } from '@netfoundry-ui/shared/recent-alerts';
import { UiLoaderModule } from '@netfoundry-ui/ui/loader';
import { OverlayComponent } from './overlay/overlay.component';

@NgModule({
    imports: [
        CommonModule,
        FeatureGettingStartedModule,
        SharedGrowlerModule,
        FeaturePageTourModule,
        SharedRecentAlertsModule,
        UiLoaderModule,
    ],
    declarations: [OverlayComponent],
    exports: [OverlayComponent],
})
export class SharedOverlayModule {}
