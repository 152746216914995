<header>
    <div class="overlay"></div>
    <div class="topButtons">
        <div
            (click)="toggleAppMenu()"
            (mouseenter)="hover('apps')"
            (mouseleave)="hover('')"
            class="clickable"
            id="AppsButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'apps' }" class="hover">Select Application</div>
        </div>
    </div>
    <div class="bottomButtons">
        <div
            (click)="open('Alerts')"
            (mouseenter)="hover('Notifications')"
            (mouseleave)="hover('')"
            class="clickable"
            id="AlertButton"
        >
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'Notifications' }" class="hover">Recent Alerts</div>
        </div>
        <div
            (click)="open('Support')"
            (mouseenter)="hover('Support')"
            (mouseleave)="hover('')"
            [ngClass]="{ open: area === 'Support' }"
            class="clickable"
            id="HelpButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'Support' }" class="hover">Support</div>
        </div>
        <div (mouseleave)="hover('')" class="clickable main" id="ProfileButton">
            <div class="main clickable"></div>
            <div class="circle"></div>
            <div
                (mouseenter)="open('Profile')"
                [ngStyle]="{ 'background-image': 'url(' + photo + ')' }"
                class="icon"
            ></div>
            <div [ngClass]="{ open: popup === 'profile' }" class="menu" id="ProfileMenu">
                <div class="menutitle" *ngIf="popup === 'profile'">{{ username }}</div>
                <div
                    *ngIf="!billingStatusInvalid && popup === 'profile'"
                    class="link"
                    id="EditProfile"
                    routerLink="/profile/"
                >
                    Edit Profile
                </div>
                <div
                    *ngIf="!billingStatusInvalid && popup === 'profile'"
                    class="link"
                    id="PersonalSettings"
                    routerLink="/user-settings/"
                >
                    Personal Settings
                </div>
                <div *ngIf="popup === 'profile'" (click)="confirmLogout()" class="link" id="LogoutButton">Logout</div>
            </div>
        </div>
    </div>
</header>

<div class="app-menu-container" *ngIf="showAppMenu" (clickOutside)="closeAppMenu()">
    <div class="app-menu-group">
        <div class="switch-to-label">Switch to</div>
        <div
            class="app-menu-item networks"
            (click)="appSelected('networks')"
            [ngClass]="{ disabled: !currentNetwork.id || !controllerService.hasZitiSession || controllerService.loadingZitiController}"
            [title]="networkAppTooltip"
        >
            <div class="app-menu-item-icon"></div>
            <div class="app-menu-item-label network">Network</div>
            <div class="check-selected" *ngIf="selectedApp === 'networks'"></div>
            <span class="spinner" *ngIf="controllerService.loadingZitiController"></span>
        </div>
        <div class="app-menu-item infrastructure" (click)="appSelected('infrastructure')">
            <div class="app-menu-item-icon"></div>
            <div class="app-menu-item-label network">Infrastructure</div>
            <div class="check-selected" *ngIf="selectedApp === 'infrastructure' && url !== BROWZER"></div>
        </div>
        <div class="app-menu-item organization" (click)="appSelected('organization')">
            <div class="app-menu-item-icon"></div>
            <div class="app-menu-item-label network">Organization</div>
            <div class="check-selected" *ngIf="selectedApp === 'organization'"></div>
        </div>
        <div
            class="app-menu-item billing"
            (click)="appSelected('billing')"
            [ngClass]="{ disabled: !currentAccount.id }"
            [title]="!currentAccount?.id ? 'No billing account associated with this organization' : ''"
        >
            <div class="app-menu-item-icon"></div>
            <div class="app-menu-item-label network">Billing</div>
            <div class="check-selected" *ngIf="selectedApp === 'billing'"></div>
        </div>
    </div>
    <div class="app-menu-group">
        <div class="switch-to-label">More</div>
        <div class="app-menu-item browzer" (click)="appSelected('browzer', BROWZER)">
            <div class="app-menu-item-icon alternate icon-BrowZer"></div>
            <div class="app-menu-item-label network">BrowZer</div>
            <div class="check-selected" *ngIf="selectedApp === 'browzer'"></div>
        </div>
    </div>
</div>
