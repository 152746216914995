import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService, FeatureService } from '@netfoundry-ui/shared/services';
import { GrowlerComponent } from '@netfoundry-ui/shared/growler';
import { OverlayService } from './overlay.service';

@Component({
    selector: 'netfoundry-ui-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.css'],
})
export class OverlayComponent implements OnInit {
    @Input() isLoggedIn = false;

    @ViewChild(GrowlerComponent, { static: true }) growler: any;

    constructor(public svc: OverlayService, public featureService: FeatureService, public apiService: ApiService) {}

    ngOnInit() {
        this.svc.growler = this.growler;
    }
}
