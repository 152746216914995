import { URLS, ZITI_URLS } from '@netfoundry-ui/shared/services';

export const OPEN_ZITI_NAVIGATOR = {
    groups: [
        {
            label: '',
            menuItems: [
                {
                    label: 'Dashboard',
                    route: ZITI_URLS.ZITI_DASHBOARD,
                    iconClass: 'icon-dashboard',
                },
            ],
        },
        {
            label: 'Core Components',
            menuItems: [
                {
                    label: 'Identities',
                    route: ZITI_URLS.ZITI_IDENTITIES,
                    iconClass: 'icon-identity',
                },
                {
                    label: 'Services',
                    route: ZITI_URLS.ZITI_SERVICES,
                    iconClass: 'icon-services',
                },
                {
                    label: 'Routers',
                    route: ZITI_URLS.ZITI_ROUTERS,
                    iconClass: 'icon-network-hub',
                },
            ],
        },
        {
            label: 'Access Rules',
            menuItems: [
                {
                    label: 'Policies',
                    route: ZITI_URLS.ZITI_SERVICE_POLICIES,
                    iconClass: 'icon-servicepolicy',
                    sublabel: '(AppWANs)',
                },
                {
                    label: 'Posture Checks',
                    route: ZITI_URLS.ZITI_POSTURE_CHECKS,
                    iconClass: 'icon-posture',
                },
            ],
        },
        {
            label: 'Management',
            menuItems: [
                {
                    label: 'Authentication',
                    route: ZITI_URLS.ZITI_CERT_AUTHORITIES,
                    iconClass: 'icon-certificates',
                },
                {
                    label: 'Sessions',
                    route: ZITI_URLS.ZITI_SESSIONS,
                    iconClass: 'icon-time',
                },
            ],
        },
    ],
};
