<lib-edge-router-form>
    <div
        *ngIf="showProvider"
        class="nf-hosted-config-container"
        slot="column-1-slot-1"
        [ngClass]="{ 'focus-hosting': focusNfHosting, disabled: disableHostPod }"
        matTooltip="{{ hostAreaToolTip }}"
        matTooltipPosition="below"
        matTooltipShowDelay="300"
    >
        <div class="nf-hosted-title-container" *ngIf="!showLoader">
            <div class="nf-hosted-title">
                <div class="netfoundryHostedIcon"></div>
                <span class="form-field-title">HOSTED BY NETFOUNDRY</span>
                <div
                    class="form-field-info infoicon"
                    matTooltip="Configure this router to be hosted by NetFoundry in a data center"
                    matTooltipPosition="below"
                    matTooltipShowDelay="300"
                ></div>
                <span class="spinner" (click)="goToProcesses()" *ngIf="svc.inProcess"></span>
            </div>

            <div
                class="save-button"
                [ngClass]="{ disabled: disableHostButton }"
                (click)="editHosting()"
                matTooltip="{{ hostButtonToolTip }}"
                matTooltipPosition="below"
                matTooltipShowDelay="300"
            >
                {{ showUpdating ? 'Updating' : (svc?.nfRouterData?.id ? 'Edit' : 'Setup') + ' Hosting' }}
            </div>
        </div>
        <netfoundry-ui-router-hosting-config
            [readonly]="true"
            *ngIf="showHostingConfig && !showLoader"
        ></netfoundry-ui-router-hosting-config>
        <div class="loader-container" *ngIf="showLoader">
          <span class="spinner"></span>
        </div>
    </div>
    <div
      class="nf-registration-container"
      slot="column-2-slot-1"
      *ngIf="svc.zitiRouterData.id"
    >
      <netfoundry-ui-edge-router-links>
      </netfoundry-ui-edge-router-links>
    </div>

</lib-edge-router-form>
