import { HTTP_INTERCEPTORS, HttpBackend, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthClientConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { ChargebeeJsAngularWrapperModule } from '@chargebee/chargebee-js-angular-wrapper';
import { FeatureActionButtonModule } from '@netfoundry-ui/feature/action-button';
import { FeatureAutoFabricModule } from '@netfoundry-ui/feature/auto-fabric';
import { FeatureCreditCardWarningModule } from '@netfoundry-ui/feature/credit-card-warning';
import { FeatureSideFilterModule } from '@netfoundry-ui/feature/side-filter';
import { FeatureViewsTemplateModule } from '@netfoundry-ui/feature/views/template';
import { FeatureAppSwitcherModule } from '@netfoundry-ui/feature/app-switcher';
import {
    NETWORK_SERVICE,
    NetworkServiceV2,
    SharedApiv2Module,
    TEMPLATE_SEARCH_SERVICE,
    V8TemplateSearchService,
    ZitiApiInterceptor,
    ZitiControllerService,
    V8BrowzerServiceSearchService,
    BROWZER_SERVICE_SEARCH_SERVICE,
} from '@netfoundry-ui/shared/apiv2';

import {
    Auth0Service,
    AUTHENTICATION,
    IAM_CONFIGURATION,
    IamConfig,
    LOGIN_SERVICE,
    LoginService,
    SharedAuthorizationModule,
} from '@netfoundry-ui/shared/authorization';
import { SharedBannerMessageModule } from '@netfoundry-ui/shared/banner-message';
import { SharedClientSyncsModule } from '@netfoundry-ui/shared/client-syncs';
import { SharedElasticsearchModule } from '@netfoundry-ui/shared/elasticsearch';
import { SharedSideBarModule } from '@netfoundry-ui/feature/side-bar';
import { SharedOverlayModule } from '@netfoundry-ui/feature/overlay';
import {
    _VERSION,
    ADVANCED_SERVICE_DIALOG,
    Attribute,
    BrowzerApp,
    CONFIG_SERVICE_DIALOG,
    EDGE_ROUTER_UPGRADE_DIALOG,
    ENDPOINT_DIALOG,
    EndpointV2,
    ENROLLMENT_DIALOG,
    ENVIRONMENT,
    EVENT_FIELDS,
    NETWORK_RESTART_DIALOG,
    NETWORK_UPGRADE_DIALOG,
    NetworkV2,
    ROUTER_DIALOG,
    SERVICE_DIALOG,
} from '@netfoundry-ui/shared/model';
import { SharedNotificationModule } from '@netfoundry-ui/shared/notification';
import { HTTP_CLIENT, MenuService, SharedServicesModule } from '@netfoundry-ui/shared/services';
// SubFeature
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgArrayPipesModule } from 'ngx-pipes';
import { NgxStripeModule } from 'ngx-stripe';
import { TimeagoModule } from 'ngx-timeago';
// NF Feature
// Main Feature
import { NgxHateoasClientConfigurationService, NgxHateoasClientModule } from '@lagoshny/ngx-hateoas-client';

import {
    FeatureFormZitiEdgeRouterFormModule,
    ZitiEdgeRouterComponent,
} from '@netfoundry-ui/feature/form/ziti-edge-router-form';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { EnrollmentInstructionsComponent, FeatureGettingStartedModule } from '@netfoundry-ui/feature/getting-started';
import {
    EdgeRouterUpgradeModalComponent,
    FeatureNetworkUpgradeModule,
    NetworkRestartModalComponent,
    NetworkUpgradeModalComponent,
} from '@netfoundry-ui/feature/network-upgrade';

import { FeatureNavigatorModule } from '@netfoundry-ui/feature/navigator';
import {
    DEACTIVATE_GUARD,
    DeactivateGuardService,
    EDGE_ROUTER_EXTENSION_SERVICE,
    EDGE_ROUTER_POLICY_EXTENSION_SERVICE,
    SERVICE_EDGE_ROUTER_POLICY_EXTENSION_SERVICE,
    ExtensionsNoopService,
    GrowlerModule,
    IDENTITY_EXTENSION_SERVICE,
    NoopLoginService,
    NoopTabInterceptorService,
    OpenZitiConsoleLibModule,
    SERVICE_EXTENSION_SERVICE,
    SERVICE_POLICY_EXTENSION_SERVICE,
    SETTINGS_SERVICE,
    SettingsService,
    SHAREDZ_EXTENSION,
    ZAC_LOGIN_SERVICE,
    ZAC_WRAPPER_SERVICE,
    ZacWrapperService,
    ZITI_DATA_SERVICE,
    ZITI_DOMAIN_CONTROLLER,
    ZITI_NAVIGATOR,
    ZITI_TAB_OVERRIDES,
    ZITI_URLS,
    ZitiControllerDataService,
} from 'ziti-console-lib';
import { ZitiEndpointFormComponent } from '@netfoundry-ui/feature/form/ziti-endpoint-form';
import { PlatformServiceFormComponent } from '@netfoundry-ui/feature/form/platform-service-form';
import { AdvancedPlatformServiceFormComponent } from '@netfoundry-ui/feature/form/advanced-platform-service-form';
import { AdvancedServiceConfigFormComponent } from '@netfoundry-ui/feature/form/advanced-service-config-form';
import {
    MultipleResourceRoutes,
    ResourceRoute,
} from '@lagoshny/ngx-hateoas-client/lib/config/hateoas-configuration.interface';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';
import { EdgeRoutersExtensionService } from '@netfoundry-ui/page/edge-routers-extension';
import { IdentitiesExtensionService } from '@netfoundry-ui/page/identities-extension';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
import { OPEN_ZITI_NAVIGATOR } from './app-nav.constants';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { URLS } from './app-urls.constants';
import { InitializerService, ZendeskConfig } from './initializer.service';
import { RecentAlertsComponent, SharedRecentAlertsModule } from '@netfoundry-ui/shared/recent-alerts';
import { CLOUD_ZITI_EVENT_FIELDS } from '../models/cloud-ziti-event-fields';
import { BrowzerAppFormComponent } from '@netfoundry-ui/feature/form/browzer-app-form';
import {UiLoaderModule} from "@netfoundry-ui/ui/loader";

function initializerFactory(
    authClientConfig: AuthClientConfig,
    iamConfig: IamConfig,
    http: HttpClient,
    handler: HttpBackend
) {
    return InitializerService.initialize(authClientConfig, iamConfig, http, handler);
}

@NgModule({
    declarations: [AppComponent],
    providers: [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: _VERSION, useValue: VERSION },
        { provide: LOGIN_SERVICE, useClass: LoginService },
        { provide: HTTP_CLIENT, useClass: HttpClient },
        { provide: IAM_CONFIGURATION, useValue: InitializerService.identityConfig },
        { provide: AUTHENTICATION, useExisting: Auth0Service },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: { fullLibraryLoader: () => import('highlight.js'), themePath: 'assets/styles/monokai.css' },
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializerFactory,
            deps: [AuthClientConfig, IAM_CONFIGURATION, HttpClient, HttpBackend],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => InitializerService.initLogRocket,
            deps: [],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: NETWORK_SERVICE, useClass: NetworkServiceV2 },
        { provide: ENDPOINT_DIALOG, useValue: ZitiEndpointFormComponent },
        { provide: NETWORK_RESTART_DIALOG, useValue: NetworkRestartModalComponent },
        { provide: NETWORK_UPGRADE_DIALOG, useValue: NetworkUpgradeModalComponent },
        { provide: ENROLLMENT_DIALOG, useValue: EnrollmentInstructionsComponent },
        { provide: ROUTER_DIALOG, useValue: ZitiEdgeRouterComponent },
        { provide: SERVICE_DIALOG, useValue: PlatformServiceFormComponent },
        { provide: ADVANCED_SERVICE_DIALOG, useValue: AdvancedPlatformServiceFormComponent },
        { provide: CONFIG_SERVICE_DIALOG, useValue: AdvancedServiceConfigFormComponent },
        { provide: ROUTER_DIALOG, useValue: ZitiEdgeRouterComponent },
        { provide: ZITI_DOMAIN_CONTROLLER, useClass: ZitiControllerService },
        { provide: ZAC_WRAPPER_SERVICE, useClass: ZacWrapperService },
        { provide: ZITI_NAVIGATOR, useValue: OPEN_ZITI_NAVIGATOR },
        { provide: ZITI_URLS, useValue: URLS },
        { provide: ZITI_TAB_OVERRIDES, useClass: NoopTabInterceptorService },
        { provide: HTTP_INTERCEPTORS, useClass: ZitiApiInterceptor, multi: true },
        { provide: SHAREDZ_EXTENSION, useClass: ExtensionsNoopService },
        { provide: EDGE_ROUTER_UPGRADE_DIALOG, useValue: EdgeRouterUpgradeModalComponent },
        { provide: DEACTIVATE_GUARD, useClass: DeactivateGuardService },
        { provide: ZITI_DATA_SERVICE, useClass: ZitiControllerDataService },
        { provide: SETTINGS_SERVICE, useClass: SettingsService },
        { provide: ZAC_LOGIN_SERVICE, useClass: NoopLoginService },
        { provide: EDGE_ROUTER_EXTENSION_SERVICE, useClass: EdgeRoutersExtensionService },
        { provide: IDENTITY_EXTENSION_SERVICE, useClass: IdentitiesExtensionService },
        { provide: SERVICE_EXTENSION_SERVICE, useClass: ExtensionsNoopService },
        { provide: EVENT_FIELDS, useValue: CLOUD_ZITI_EVENT_FIELDS },
        { provide: TEMPLATE_SEARCH_SERVICE, useClass: V8TemplateSearchService },
        { provide: BROWZER_SERVICE_SEARCH_SERVICE, useClass: V8BrowzerServiceSearchService },
        { provide: SERVICE_POLICY_EXTENSION_SERVICE, useClass: ExtensionsNoopService },
        { provide: EDGE_ROUTER_POLICY_EXTENSION_SERVICE, useClass: ExtensionsNoopService },
        { provide: SERVICE_EDGE_ROUTER_POLICY_EXTENSION_SERVICE, useClass: ExtensionsNoopService }
    ],
    imports: [
        CommonModule,
        ClickOutsideModule,
        FormsModule,
        UiPipesModule,
        FeatureAppSwitcherModule,
        FeatureViewsTemplateModule,
        ChargebeeJsAngularWrapperModule,
        FeatureAutoFabricModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FeatureActionButtonModule,
        FeatureCreditCardWarningModule,
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
        NgArrayPipesModule,
        NgxHateoasClientModule.forRoot(),
        NgxPaginationModule,
        NgxStripeModule.forRoot(environment.billingConfig.stripe),
        SharedApiv2Module.forRoot(),
        SharedAuthorizationModule.forRoot(InitializerService.authorizationConfig),
        SharedBannerMessageModule,
        SharedClientSyncsModule.forRoot(InitializerService.clientSyncsConfig),
        SharedElasticsearchModule.forRoot(InitializerService.elasticConfig),
        SharedNotificationModule.forRoot(InitializerService.notificationConfig),
        TimeagoModule.forRoot(),
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
        RouterModule,
        UiPipesModule,
        FeatureSideFilterModule,
        SharedServicesModule,
        AuthModule.forRoot(),
        FeatureFormZitiEdgeRouterFormModule,
        FeatureGettingStartedModule,
        LottieModule.forRoot({ player: () => player }),
        FeatureNetworkUpgradeModule,
        FeatureNavigatorModule,
        SharedSideBarModule,
        SharedOverlayModule,
        GrowlerModule,
        OpenZitiConsoleLibModule,
        MatTooltipModule,
        SharedRecentAlertsModule,
        UiLoaderModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(hateoasConfig: NgxHateoasClientConfigurationService) {
        const v2ResourceRoute: ResourceRoute = {
            rootUrl: environment.v2apiUrl,
        };
        const v3ResourceRoute: ResourceRoute = {
            rootUrl: environment.v3apiUrl,
        };
        const multipleRoutes: MultipleResourceRoutes = {
            defaultRoute: v3ResourceRoute,
            networkResourceRoute: v3ResourceRoute,
        };
        hateoasConfig.configure({
            http: multipleRoutes,
            logs: { verboseLogs: false },
            useTypes: {
                resources: [NetworkV2, Attribute, EndpointV2],
            },
            halFormat: {
                collections: {
                    embeddedOptional: true,
                },
            },
            cache: { enabled: true, lifeTime: 200 },
            isProduction: environment.production,
            pagination: {
                defaultPage: {
                    page: 0,
                    size: environment.pageSize ?? 50,
                },
            },
        });
    }
}
