<app-page-tour *ngIf="isLoggedIn"></app-page-tour>
<app-growler></app-growler>
<app-recent-alerts
    (hideGrowlersEvent)="svc.closeGrowlers()"
    *ngIf="isLoggedIn"
    [currentNetwork]="svc.currentNetwork"
    [isOpen]="svc.isGrowlersOpen"
></app-recent-alerts>

<div
    *ngIf="svc.showQuickStart && featureService.showGettingStarted"
    class="QuickStart"
    routerLink="/getting-started"
></div>
<app-progress-bar *ngIf="apiService.tutorialActive"></app-progress-bar>

<div class="root-app-loading-indicator">
    <app-loading-indicator *ngIf="svc.appLoading" [isLoading]="svc.appLoading"></app-loading-indicator>
</div>
