import { Injectable } from '@angular/core';
import { ApiService, FeatureService, MenuService, URLS } from '@netfoundry-ui/shared/services';
import { Subscription } from 'rxjs';
import { GrowlerService } from '@netfoundry-ui/shared/growler';
import _, { get } from 'lodash';
import { Router } from '@angular/router';
import { AccountService } from '@netfoundry-ui/feature/shared-services';

const EnjoyHint: any = get(window, 'EnjoyHint', (p?: any) => {});

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    private url = '';
    private subscription = new Subscription();
    private billingInvalid = false;
    private tenantId = '';
    private tenantLabel = '';
    public isNewSelfService = false;
    public isSelfService = false;
    public isSelfServiceTeams = false;
    public appLoading = false;
    public showQuickStart = false;
    public isGrowlersOpen = false;
    public currentNetwork: any;
    public growler: any;

    constructor(
        public apiService: ApiService,
        private menuService: MenuService,
        private growlerService: GrowlerService,
        private featureService: FeatureService,
        private accountService: AccountService,
        private router: Router
    ) {
        this.subscription.add(
            this.apiService.appLoading.subscribe((isLoading) => {
                this.appLoading = isLoading;
            })
        );
        this.subscription.add(
            this.growlerService.data$.subscribe((data) => {
                this.showTheGrowler(data);
            })
        );
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((network) => {
                this.currentNetwork = network;
            })
        );
        this.subscription.add(
            this.router.events.subscribe((event: any) => {
                this.url = event.url;
                this.handleQuickStartVisibility();
            })
        );
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                localStorage.setItem('tenantId', tenant.id);
                this.tenantId = tenant.id;
                this.tenantLabel = tenant.label;
                this.isNewSelfService = localStorage.getItem('nf_new_self_service_' + this.tenantLabel) === 'true';
                if (this.isNewSelfService) {
                    localStorage.removeItem('nf_new_self_service_' + this.tenantLabel);
                }
            })
        );
        this.subscription.add(
            this.accountService.currentAccount.subscribe((account: any) => {
                this.isSelfService = account?.billingMode === 'SelfService';
                this.handleQuickStartVisibility();
            })
        );
        this.subscription.add(
            this.apiService.currentSubscription.subscribe((subscription) => {
                this.isSelfServiceTeams = this.featureService.isSelfServiceTeamsTier(subscription);
                this.handleQuickStartVisibility();
            })
        );
        this.subscription.add(
            this.apiService.billingStatusInvalid.subscribe((isInvalid: boolean) => {
                this.billingInvalid = isInvalid;
                this.handleQuickStartVisibility();
            })
        );
    }

    showGrowlers() {
        if (this.menuService.areaId === '') {
            this.menuService.setArea('Network');
        }
        this.isGrowlersOpen = true;
    }

    closeGrowlers() {
        this.isGrowlersOpen = false;
    }

    showTheGrowler(data: any) {
        this.growler.title = data.title;
        this.growler.subtitle = data.subtitle;
        this.growler.level = data.level;
        this.growler.content = data.content;
        this.growler.show = true;
        setTimeout(() => {
            this.growler.show = false;
        }, 5000);
        return this.growler;
    }

    private handleQuickStartVisibility() {
        this.showQuickStart = false;
        switch (this.url) {
            case URLS.SIGNUP:
            case URLS.GETTING_STARTED:
            case URLS.LOGIN:
            case URLS.SAMPLE_SERVICE:
            case URLS.OLD_LOGIN:
            case URLS.SIGNUP_COMPLETE:
            case URLS.SIGNUP_RETURN:
            case URLS.PAGENOTFOUND:
            case URLS.SELFSERVICE: {
                this.showQuickStart = false;
                break;
            }
            default: {
                this.showQuickStart = (this.isSelfService || this.isNewSelfService) && !this.billingInvalid;
            }
        }
        if (this.showQuickStart && !this.apiService?.tutorialActive) {
            this.highlightQuickStartButton();
        }
    }

    private highlightQuickStartButton() {
        const showHint = localStorage.getItem('nf_show_quick_start_hint_' + this.tenantLabel) === 'true';
        if (!showHint || !this.featureService.showGettingStarted) {
            return;
        }
        const enjoy_hint_options: any = {
            showSkip: true,
            showNext: true,
            showPrev: true,
            btnSkipText: 'Got it',
            radius: 20,
            onEnd: () => {},
            keyCode: '',
            top: 100,
            onSkip: () => {},
        };
        const enjoyhint_instance: any = new EnjoyHint(enjoy_hint_options);
        const hints = [
            {
                event: 'quick-start-badge',
                selector: '.QuickStart',
                //arrowColor: 'transparent',
                margin: 0,
                shape: 'circle',
                skipButton: { className: 'quickStartSkip', text: 'Got it' },
                description:
                    '<h2>Here\'s a hint</h2><p>Use the <text style="color: #4fcbff">Quick Start</text> button at any time to return to the <text style="color: #4fcbff">Getting Started</text> page. <p>There you\'ll find links to our documentation and tutorials</p>',
                onBeforeStart: () => {},
            },
        ];
        _.delay(() => {
            enjoyhint_instance.set(hints);
            enjoyhint_instance.run();
        }, 100);
        localStorage.removeItem('nf_show_quick_start_hint_' + this.tenantLabel);
    }
}
