<lib-identities>
  <div *ngIf="showToggle" slot="list-page-subheader" class="admin-toggle-container">
    <div class="table-toggle" [ngClass]="{disabled: svc.isAdminColumnFilterActive}">
      <span class="toggle-text" matTooltip="{{ isAdminToolTip }}" matTooltipPosition="below"
            matTooltipShowDelay="300">Admin Identities</span>
      <div class="toggle" [ngClass]="{ on: toggleOn }" (click)="toggled()" matTooltip="{{ isAdminToolTip }}" matTooltipPosition="below"
           matTooltipShowDelay="300">
        <span [hidden]="!toggleOn" class="on-label">SHOW</span>
        <span [hidden]="toggleOn" class="off-label">HIDE</span>
        <div class="switch"></div>
      </div>
    </div>
  </div>
</lib-identities>
