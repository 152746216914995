export const URLS = {
    ZITI_DASHBOARD: '/dashboard',
    ZITI_IDENTITIES: '/identities',
    ZITI_JWT_SIGNERS: '/jwt-signers',
    ZITI_SERVICES: '/services',
    ZITI_ROUTERS: '/routers',
    ZITI_TRANSIT_ROUTERS: '/transit-routers',
    ZITI_CONFIGS: '/configs',
    ZITI_CONFIG_TYPES: '/config-types',
    ZITI_RECIPES: '/recipes',
    ZITI_TERMINATORS: '/terminators',
    ZITI_AUTH_POLICIES: '/auth-policies',
    ZITI_SERVICE_POLICIES: '/service-policies',
    ZITI_ROUTER_POLICIES: '/router-policies',
    ZITI_SERVICE_ROUTER_POLICIES: '/service-router-policies',
    ZITI_POSTURE_CHECKS: '/posture-checks',
    ZITI_BROWZER_CAS: '/browzer-cert-auth',
    ZITI_CERT_AUTHORITIES: '/certificate-authorities',
    ZITI_SESSIONS: '/sessions',
    ZITI_API_SESSIONS: '/api-sessions',
    ZITI_LOGIN: '/login',
    ZITI_ORGANIZATION: '/organization',
    ZITI_PROFILE: '/profile',
    ZITI_SERVERS: '/servers',
    ZITI_SETTINGS: '/settings',
    ZAC_LOGIN: '/login',
};
