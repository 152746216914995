import { AuthClientConfig } from '@auth0/auth0-angular';
import { IamConfig } from '@netfoundry-ui/shared/authorization';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
import LogRocket from 'logrocket';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';

declare const gtag;

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
    accountUrl = 'netfoundry.zendesk.com';
    override lazyLoad = true;

    callback(zE: any): any {
        zE.setLocale('en');
        zE.activate();
    }
}

@Injectable({ providedIn: 'root' })
export class InitializerService {
    static identityConfig: IamConfig = {
        url: environment.identityConfig.url,
        invitationUrl: environment.identityConfig.invitationUrl,
        intermediateReturnUrl: environment.identityConfig.intermediateReturnUrl,
        loginReturnUrl: environment.identityConfig.loginReturnUrl,
    };
    static clientSyncsConfig = {
        url: environment.clientSyncsApiUlr,
    };
    static elasticConfig = { url: environment.apiUrl, usePublicApi: true };
    static authorizationConfig = {
        url: environment.authorizationUrl,
    };
    static notificationConfig = {
        url: environment.notificationUrl,
    };
    static authConfig: any = {
        clientId: environment.authconfig.clientID,
        domain: environment.authconfig.domain,
        audience: environment.authconfig.audience,
        errorPath: environment.identityConfig.loginReturnUrl,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        redirect_uri: environment.authconfig.callbackURL,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        httpInterceptor: {
            allowedList: [
                {
                    uriMatcher: (uri) => {
                        if (uri.indexOf('/tenants/label') >= 0) return false;
                        if (uri.indexOf('/tenants/authorize') >= 0) return false;
                        if (uri.indexOf('/tenants/forgotten-site') >= 0) return false;
                        if (uri.indexOf('/identity/v1/identity-provider-types') >= 0) return false;
                        if (uri.indexOf('/rest/v1/countries') >= 0) return false;
                        if (uri.indexOf('/identity/v1/signup') >= 0) return false;
                        if (uri.indexOf('/identity/v1/tenants/label/') >= 0) return false;
                        if (uri.indexOf('/invitation?') >= 0) return false;
                        if (uri.indexOf('/customer/v1/email-verification') >= 0) return false;
                        if (uri.indexOf('/customer/v1/signup') >= 0) return false;
                        if (uri.indexOf('/customer/v1/otps') >= 0) return false;
                        if (uri.indexOf('nf-console-branding') >= 0) return false;
                        if (uri.indexOf('/edge/management/v1') >= 0) return false;
                        return !(uri.indexOf('/identity/v1/invitations/') >= 0 && uri.indexOf('/revoke') < 0);
                    },
                },
            ],
        },
    };

    static initialize(config: AuthClientConfig, identityConfig: IamConfig, http: HttpClient, handler: HttpBackend) {
        InitializerService.initAnalytics();
        InitializerService.initChargebee();
        return InitializerService.initAuthConfig(config, http, handler, identityConfig);
    }

    static initLogRocket(): void {
        if (!(environment as any).disableLogRocket) {
            LogRocket.init(`pnjasd/netfoundry-${environment.domain}`, {
                release: `${VERSION.version} ${VERSION.buildDate} ${VERSION.buildTime}`,
                shouldParseXHRBlob: true,
                browser: {
                    urlSanitizer: (url) => {
                        let sanitizedUrl = url;
                        sanitizedUrl = sanitizedUrl.replace(/access_token=([^&]*)/, 'access_token=**redacted**');
                        sanitizedUrl = sanitizedUrl.replace(/identityId=([^&]*)/, 'identityId=**redacted**');
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sanitizedUrl = sanitizedUrl.replace(/\/client\/(.*)/i, '/client/**redacted**');
                        return sanitizedUrl;
                    },
                },
                network: {
                    requestSanitizer: (request) => {
                        if (request.headers['Authorization']) {
                            request.headers['Authorization'] = '**redacted**';
                        }
                        return request;
                    },

                    responseSanitizer: (response) => {
                        if (response.body) {
                            if (response.body['nfToken']) {
                                response.body = '**redacted**';
                            }
                        }
                        return response;
                    },
                },
            });
            LogRocket.getSessionURL(function (sessionURL) {
                gtag('send', {
                    hitType: 'event',
                    eventCategory: 'LogRocket',
                    eventAction: sessionURL,
                });
            });
        }
    }

    private static initAuthConfig(
        config: AuthClientConfig,
        http: HttpClient,
        handler: HttpBackend,
        identityConfig: IamConfig
    ) {
        const tenantLabel = localStorage.getItem('tenantLabel');
        if (!tenantLabel) {
            const currentUri = window.location?.pathname + window.location?.search;
            if (currentUri.indexOf('/invitation') >= 0 || currentUri.indexOf('/trial') >= 0) {
                InitializerService.authConfig.errorPath = currentUri;
            }
            config.set(InitializerService.authConfig);
            return () => Promise.resolve();
        } else {
            return () => {
                // Create a new HttpBackend handler so the AuthHttpInterceptor doesn't intercept the init request
                http = new HttpClient(handler);
                const isVanityUrl = localStorage.getItem('isVanityUrl') === 'true';
                let redirectUri = environment.authconfig.callbackURL;
                if (isVanityUrl) {
                    redirectUri = InitializerService.initVanity(redirectUri, tenantLabel);
                }

                const fullpath = `${identityConfig.url}tenants/authorize-initiate`;
                const body = { tenantLabel: tenantLabel, intermediateReturnUrl: '' };
                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', 'application/json; charset=utf-8');
                return lastValueFrom(http.post(fullpath, JSON.stringify(body), { headers: headers })).then(
                    (result: any) => {
                        InitializerService.authConfig.nfToken = result.nfToken;
                        InitializerService.authConfig.redirect_uri = redirectUri;
                        return config.set(InitializerService.authConfig);
                    }
                );
            };
        }
    }

    private static initChargebee() {
        window['Chargebee'].init({
            site: environment.chargebeeConfig.site,
            publishableKey: environment.chargebeeConfig.publishableKey,
        });
    }

    private static initAnalytics() {
        if (environment.domain !== 'localhost') {
            const script = document.createElement('script');
            script.src = 'assets/scripts/google-analytics.js';
            document.body.appendChild(script);
        }
    }

    private static initVanity(redirectUri: string, tenantLabel: string) {
        let newUri = '';
        if (redirectUri.indexOf('https://') >= 0) {
            const redirectLocation = redirectUri.slice(8);
            newUri = `https://${tenantLabel}.${redirectLocation}`;
        } else {
            const redirectLocation = redirectUri.slice(7);
            newUri = `http://${tenantLabel}.${redirectLocation}`;
        }
        return newUri;
    }
}
