import { Component, Input, OnInit } from '@angular/core';
import { FeatureService, MenuService } from '@netfoundry-ui/shared/services';

@Component({
    selector: 'netfoundry-ui-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
    @Input() hide = false;
    @Input() isCloudZiti = false;
    @Input() disableNavigation = false;

    hideNavigator = false;
    closeTimeout: any;
    isGrowlersOpen = false;

    constructor(public featureService: FeatureService, public menuService: MenuService) {}

    ngOnInit() {
        this.menuService.area.subscribe((area) => {
            this.hideNavigator = area.length <= 0;
        });
    }

    showGrowlers() {
        if (this.menuService.areaId === '') {
            this.menuService.setArea('Network');
        }
        this.isGrowlersOpen = true;
    }

    closeGrowlers() {
        this.isGrowlersOpen = false;
    }

    close() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
        // this.logger.info('msst');
        if (!this.menuService.locked && this.menuService.isOpen()) {
            // this.logger.info('not lockt');
            this.closeTimeout = setTimeout(() => {
                // this.logger.info('my timeout');
                this.menuService.setArea('');
            }, 2000);
        }
    }

    removeClose() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
    }
}
