<div *ngIf="isCloudZiti">
    <link href="/assets/styles/icons.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/tables.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/ziti.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/header.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/navigation.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/forms.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/growler.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/spectrum.css" rel="stylesheet" type="text/css" />
    <link href="/assets/scripts/components/leaflet/leaflet.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/chart.min.css" rel="stylesheet" type="text/css" />
    <link href="/assets/scripts/components/column.css" rel="stylesheet" type="text/css" />
    <link href="/assets/scripts/components/codemirror/codemirror.css" rel="stylesheet" type="text/css" />
    <link href="/assets/styles/select2.css" rel="stylesheet" type="text/css" />
    <link *ngIf="brandingService.darkModeEnabled" href="/assets/styles/dark.css" rel="stylesheet" type="text/css" />
    <link *ngIf="brandingService.darkModeEnabled" href="/assets/styles/darkmode.css" rel="stylesheet" type="text/css" />
</div>
